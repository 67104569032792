.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;

  &.component-loader {
    margin-top: -60px;
  }

  .effect-1,
  .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba(121, 97, 249, 1);
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .effect-1 {
    animation: rotate 1s ease infinite;
  }
  .effect-2 {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }
  .effect-3 {
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba(121, 97, 249, 1);
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .loading .effects {
    transition: all 0.3s ease;
  }
}
.fallback-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

.fallback-spinner {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 9999;
  background-color: white;
}


.patterns {
  // height: 100vh;
}



svg .text-anim {
  font-family: Lora !important;
  letter-spacing: 10px;
  stroke: #05a;
  font-size: 100px;
  font-weight: 700;
  stroke-width: 3;
  font-style: italic; 
  animation: textAnimate 3s infinite alternate;
  
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset:  20%;
    fill:white

  }

  50% {
    stroke-dasharray: 50% 0;
    stroke-dashoffstet: -20%;
    fill:#0a5
  }
  
  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffstet: -20%;
    fill:#05a;
    color: #05a;
  }
}

.equilizer {
  height: 50%;
  width: 50%;
  margin-left: 25%;
  transform: rotate(180deg);
}

.bar {
  fill: #e2e1ef;
  width: 18px;
  animation: equalize 4s 0s infinite;
}

.bar:nth-child(1) { 
  animation-delay: -1.9s;
}

.bar:nth-child(2) { 
  animation-delay: -2s;
}

.bar:nth-child(3) { 
  animation-delay: -2.3s;
}

.bar:nth-child(4) { 
  animation-delay: -2.4s;
}

.bar:nth-child(5) { 
  animation-delay: -2.1s;
}

@keyframes equalize {
  0% {
    height: 60px;
  }
  4% {
    height: 50px;
  }
  8% {
    height: 40px;
  }
  12% {
    height: 30px;
  }
  16% {
    height: 20px;
  }
  20% {
    height: 30px;
  }
  24% {
    height: 40px;
  }
  28% {
    height: 10px;
  }
  32% {
    height: 40px;
  }
  36% {
    height: 60px;
  }
  40% {
    height: 20px;
  }
  44% {
    height: 40px;
  }
  48% {
    height: 70px;
  }
  52% {
    height: 30px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 30px;
  }
  64% {
    height: 50px;
  }
  68% {
    height: 60px;
  }
  72% {
    height: 70px;
  }
  76% {
    height: 80px;
  }
  80% {
    height: 70px;
  }
  84% {
    height: 60px;
  }
  88% {
    height: 50px;
  }
  92% {
    height: 60px;
  }
  96% {
    height: 70px;
  }
  100% {
    height: 80px;
  }
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear alternate infinite;
  position: absolute;
} 

.svg-leaf-left {
  position: absolute;
  top: 20%;
  left: 42%;
  height: 30%;
  width: 30%;
  transition: all 0.25s ease !important;
  transform: translateX(-100%);
  opacity: 0;
  //margin-left: 18%;
}

.svg-leaf-right {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transition: all 0.25s ease !important;
  position: absolute;
  top: 20%;
  left: 27%;
  height: 30%;
  width: 30%;
  transform: translateX(100%);
  opacity: 0;
}

.svg-straight-normal {
  opacity: 1 !important;
}

.svg-straight {
  transition: all 0.15s ease !important;
  width: 30%;
  height: 30%;
  transform: rotate(-35deg);
  /* top: -50%; */
  margin-top: 3%;
  margin-left: 35%;
  opacity: 0;
}

.svg-leaf-normal {
  transform: translateX(0%) !important;
  opacity: 1 !important;
}

.svg-flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  opacity: 1 !important;
}

.svg-txt {
  width: 100%;
  margin-bottom: -25%;
}

@keyframes dash {
  from {
    stroke-dashoffset: 950;
  }
  to {
    stroke-dashoffset: 0;
  }
}