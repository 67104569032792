//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #055a60;
$secondary: #eb5c37;
$success: #139c09;
$danger: #db1b06;
$warning: #ffb81c;
$info: #65b4e5;
$dark: #2f2f2f;
$light: #ffffff;

$violet: #7b76b6;
$light-pink: #e58ebb;
$light-coral: #e98276;
$patrol: #0097a9;
$jade: #17b0ad;

button,
input,
textarea {
  border-radius: 2px !important;
}

@font-face {
  font-family: DefaultFont;
  src: url('./../../fonts/InfraRegular.otf');
}

* {
  font-family: DefaultFont !important;
}
